// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import Img from '@components/ImageWrapper';

import { translate as t } from '../../utils/translate';

const BlogPostCard = ({ image, title, excerpt, link }) => {
  const TEXTS = {
    LEARN_MORE: t('learnMore'),
  };
  return (
    <div className={Style.blogPostCard}>
      <div className={Style.linkContainer}>
        <div className={Style.linkCard}>
          <a className={Style.link} target="_blank" href={link}>
            {TEXTS.LEARN_MORE}
          </a>
        </div>
      </div>
      <div className={Style.infosContainer}>
        <Img src={image} className={Style.image} />
        <div className={Style.cardContent}>
          <p className={Style.title}>{title}</p>
          <p
            className={Style.excerpt}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;

/*
props:
image => src da imagem (string)
title => título do post (string)
excerpt => resumo do post (string)
link => link para a página do post (string)
*/
