// TODO: REFACTORING
import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './HeroAward.styles';
import solveImageLink from '@utils/solveImageLink';

const HeroAward = ({
  title,
  description,
  image,
  trophy,
  text_color,
  text_shadow,
}) => {
  image = solveImageLink(image);
  trophy = solveImageLink(trophy);

  return (
    <Styled.Wrapper>
      <Styled.WrapperImage img={image && image.src}></Styled.WrapperImage>
      <Styled.WrapperContent className="container">
        <Styled.ContentText>
          <Styled.HeaderText color={text_color} shadow={text_shadow}>
            {title}
          </Styled.HeaderText>
          <Styled.HeaderDescription color={text_color} shadow={text_shadow}>
            {description}
          </Styled.HeaderDescription>
        </Styled.ContentText>
        <Styled.ContentImage>
          <img src={trophy && trophy.src} alt={title} />
        </Styled.ContentImage>
      </Styled.WrapperContent>
    </Styled.Wrapper>
  );
};

HeroAward.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  trophy: PropTypes.object.isRequired,
  text_color: PropTypes.string,
  text_shadow: PropTypes.bool,
};

export default HeroAward;
