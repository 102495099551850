// TODO: REFACTORING
import React, { useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import Logo from './Logo';
import Icon from '@components/SecurityCarousel/Icon';
import ForwardIcon from '@components/AwardTheme/icons/ForwardIcon';
import FacebookIcon from '@components/AwardTheme/icons/FacebookIcon';
import TwitterIcon from '@components/AwardTheme/icons/TwitterIcon';
import LinkedinIcon from '@components/AwardTheme/icons/LinkedinIcon';
import AwardCarousel from './components/AwardCarousel';
import * as Styled from './AwardTheme.styles';
import Img from '@components/ImageWrapper';

const AwardTheme = ({
  title,
  subtitle,
  trophy,
  description,
  articles,
  link_award,
  other_award,
  hasHeader,
  isLast,
}) => {
  const customeSlider = useRef();

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 2,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 728,
        settings: {
          className: 'center',
          centerMode: true,
          infinite: true,
          centerPadding: '15px',
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  return (
    <Styled.Wrapper radius={isLast}>
      <Styled.HeaderLineTitle showing={hasHeader}>
        <Logo />
        <h1>Awards</h1>
      </Styled.HeaderLineTitle>
      <Styled.HeaderLine />
      <Styled.HeaderGradient />
      <Styled.ContainerSpotlight />
      <Styled.AwardTitle>
        <Styled.AwardTitleTrophy>
          <Img src={trophy} alt={title} type="image" />
        </Styled.AwardTitleTrophy>
        <Styled.AwardTitleText>
          <h1>{title}</h1>
        </Styled.AwardTitleText>
      </Styled.AwardTitle>
      <Styled.AwardTitleBkg />
      <Styled.ContainerDescription>
        <h1>{subtitle}</h1>
        <p>{description}</p>
      </Styled.ContainerDescription>
      <Styled.ContainerMedia>
        <h6>{articles.title}</h6>
        <div>
          {articles.award_publications.map((item, index) => (
            <a
              href={item.link}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img
                src={item.image}
                alt={item.link}
                type="image"
                className="article__image"
              />
            </a>
          ))}
        </div>
      </Styled.ContainerMedia>
      <Styled.ContainerSocialMedia>
        <Styled.SocialMediaContent>
          <Styled.SocialMediaUrl>
            <Icon />
            <a href={link_award} target="_blank" rel="noopener noreferrer">
              {/* {link_award && link_award.substring(0, 75)} */}
              {link_award}
            </a>
            {/* <Styled.ShadowText /> */}
          </Styled.SocialMediaUrl>
          <Styled.SocialMediaButton>
            <ForwardIcon color="#f5f5f5" />
            <FacebookIcon color="#909090" />
            <TwitterIcon color="#909090" />
            <LinkedinIcon color="#909090" />
          </Styled.SocialMediaButton>
        </Styled.SocialMediaContent>
        <Styled.SocialMediaButtonMobile>
          <ForwardIcon color="#f5f5f5" />
          <FacebookIcon color="#909090" />
          <TwitterIcon color="#909090" />
          <LinkedinIcon color="#909090" />
        </Styled.SocialMediaButtonMobile>
      </Styled.ContainerSocialMedia>
      <Styled.SocialMediaOther>
        <AwardCarousel {...other_award} />
      </Styled.SocialMediaOther>
    </Styled.Wrapper>
  );
};

AwardTheme.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  trophy: PropTypes.object.isRequired,
  description: PropTypes.string,
  articles: PropTypes.object.isRequired,
  link_award: PropTypes.string,
  other_award: PropTypes.object.isRequired,
  hasHeader: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default AwardTheme;
