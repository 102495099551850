import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  min-height: 1550px;
  margin-bottom: -875px;
  background-color: #030202;

  @media screen and (max-width: 768px) {
    min-height: 1050px;
    margin-bottom: -310px;
  }
`;

export const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 750px;
  transform: rotateY(180deg);
  filter: grayscale(1) brightness(0.7);
  background-image: linear-gradient(to bottom, transparent 40%, #030202 85%),
    url(${({ img }) => img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;

  * {
    filter: grayscale(0) brightness(1);
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
`;

export const WrapperContent = styled.div.attrs({
  className: 'container',
})`
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  padding: 0;

  @media screen and (max-width: 1025px) {
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 42%;
  margin-top: 100px;

  @media (min-width: 320px) and (max-width: 1024px) {
    width: 75%;
    margin-top: -20px;
    margin-left: -5px;
  }
`;

export const HeaderText = styled.div`
  width: 100%;
  font-size: 60px;
  font-weight: 700;
  color: ${({ color }) => color};
  text-shadow: ${({ shadow }) =>
    shadow ? '1px 1px 2px rgba(0,0,0,.34)' : 'none'};

  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 1.5rem;
  }
`;
export const HeaderDescription = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.56;
  color: ${({ color }) => color};
  text-shadow: ${({ shadow }) => (shadow ? '1px 1px 2px #444444' : 'none')};

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const ContentImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
  max-height: 600px;
  margin-left: -50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 231px;
  }

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100px;
    margin-left: -10px;
  }
`;
