// TODO: REFACTORING
import React from 'react';

const LinkedinIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43.1" height="43.1">
      <path
        d="M21.6 0A21.6 21.6 0 1043 21.6 21.6 21.6 0 0021.6 0zm-6.3 32.6H10V16.8h5.3zm-2.6-18a2.7 2.7 0 110-5.4 2.7 2.7 0 110 5.4zm21.5 18H29V24c0-2-.8-3.5-2.7-3.5a2.9 2.9 0 00-2.7 1.9 3.6 3.6 0 00-.1 1.3v8.8h-5.3V16.8h5.3V19a5.2 5.2 0 014.7-2.6c3.4 0 6 2.3 6 7.1zm0 0"
        fill={color}
      />
    </svg>
  );
};

export default LinkedinIcon;
