// TODO: REFACTORING
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';

import * as Styled from './../AwardTheme.styles';

const AwardCarousel = ({ title, other_publications }) => {
  const swiperRef = useRef(null);

  const params = {
    direction: 'horizontal',
    mousewheel: true,
    loop: false,
    speed: 600,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: -20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: -15,
      },
    },
  };

  const gotoNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const gotoPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <Styled.SocialMediaOtherContent>
      <h1>{title}</h1>
      <Styled.WrapperCarousel>
        <Styled.SliderButtonPrev onClick={() => gotoPrev()} />
        <Styled.CarouselImages>
          <Swiper {...params} ref={swiperRef}>
            {other_publications.map((item, index) => (
              <Styled.ContainerSwiper key={index}>
                <Styled.CertificateBlock>
                  <Styled.ContainerBoxMediaDescription>
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                    {item.publications.map((pub, index) => (
                      <a
                        href={pub.link}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={pub.image.localFile.childImageSharp.fluid.src}
                          alt={pub.link}
                        />
                      </a>
                    ))}
                  </Styled.ContainerBoxMediaDescription>
                </Styled.CertificateBlock>
              </Styled.ContainerSwiper>
            ))}
          </Swiper>
        </Styled.CarouselImages>
        <Styled.SliderButtonNext onClick={() => gotoNext()} />
      </Styled.WrapperCarousel>
    </Styled.SocialMediaOtherContent>
  );
};

AwardCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  other_publications: PropTypes.array.isRequired,
};

export default AwardCarousel;
