import styled, { css } from 'styled-components';

import SpotlightFrontImage from '@images/spotlight-front.svg';
import SpotlightBackImage from '@images/spotlight-back.svg';
import ArrowSlider from '@images/arrows/arrow-slider.png';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 150vh;
  background-color: #030202;
  border-radius: ${({ radius }) => (radius ? '0 0 140px 140px' : '0')};
  padding-bottom: ${({ radius }) => (radius ? '400px' : '0')};
  margin-bottom: ${({ radius }) => (radius ? '50px' : '0')};

  @media screen and (max-width: 768px) {
    margin-top: -100px;
    border-radius: 0;
    padding-bottom: ${({ radius }) => (radius ? '200px' : '0')};
    margin-bottom: ${({ radius }) => (radius ? '50px' : '0')};
  }
`;

export const HeaderLineTitle = styled.div`
  display: ${({ showing }) => (showing ? 'flex' : 'none')};
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  z-index: 4;
  left: 0;
  width: 480px;
  height: 115px;
  padding: 10px;
  padding-left: 70px;
  background: #030202;
  > svg {
    width: 175px;
    height: auto;
    padding-right: 5px;
  }

  > h1 {
    float: right;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 600;
    padding-top: 5px;
    background: linear-gradient(180deg, #ffffff 50%, #333 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 768px) {
    width: 200px;
    height: 55px;
    margin-top: 15px;
    padding-left: 14px;
    background: transparent
      linear-gradient(
        to bottom,
        #38383800 10%,
        #00000073 50%,
        #000000d4 50%,
        #000000 100%
      );

    > svg {
      width: 220px;
      height: auto;
      padding-top: 5px;
    }

    > h1 {
      font-size: 1.5rem;
      font-weight: 600;
      padding-top: 10px;
    }
  }
`;

export const HeaderLine = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  width: 92%;
  height: 4px;
  margin-top: 50px;
  background-color: #00bfa5;

  @media screen and (max-width: 768px) {
    width: 95%;
    height: 3px;
  }
`;

export const HeaderGradient = styled.div`
  position: absolute;
  z-index: 3;
  right: 0;
  width: 20%;
  height: 150px;
  background: transparent
    linear-gradient(
      to right,
      #38383800 0%,
      #00000073 26%,
      #000000d4 61%,
      #000000 100%
    );

  @media screen and (max-width: 768px) {
    margin-top: 30px;
    height: 55px;
  }
`;

export const ContainerSpotlight = styled.div`
  position: relative;
  z-index: 7;
  width: 435px;
  height: 300px;
  background-image: url(${SpotlightFrontImage}), url(${SpotlightBackImage});
  background-repeat: no-repeat, no-repeat;
  background-position: top center, top center;
  margin: 0 auto;
  top: 54px;

  @media screen and (max-width: 768px) {
    width: 150px;
    height: 110px;
    left: 50px;
    background-size: contain;
  }
`;

export const AwardTitle = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-height: 380px;
  margin-top: -145px;

  @media screen and (max-width: 768px) {
    height: 290px;
  }
`;

export const AwardTitleTrophy = styled.div`
  position: relative;
  z-index: 6;
  margin-bottom: -150px;
  width: 100%;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 135px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: -70px;
    top: 40px;
    right: calc(50% - 7.5% - 52px);
    width: 15%;
  }
`;

export const AwardTitleText = styled.div`
  position: relative;
  z-index: 5;
  top: -35px;
  width: 100%;
  float: left;

  & > h1 {
    width: 100%;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 115px;
    font-weight: 600;
    background: linear-gradient(180deg, #ffffff 40%, #333 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 768px) {
    top: 55px;
    left: 0;

    & > h1 {
      font-size: 40px;
      max-height: calc(40px * 2);
      overflow: hidden;

      &::before {
        position: absolute;
        content: '.....';
        bottom: 0;
        right: 30px;
      }
    }
  }
`;

export const AwardTitleBkg = styled.div.attrs({
  className: 'container',
})`
  position: relative;
  z-index: 1;
  top: -150px;
  left: -50px;
  width: 65%;
  height: 110px;
  margin-bottom: -110px;
  padding: 10px;
  background: transparent
    linear-gradient(
      to bottom,
      #38383800 0%,
      #00000073 26%,
      #000000d4 61%,
      #000000 100%
    );

  @media screen and (max-width: 768px) {
    top: -90px;
    left: -15px;
    width: 80%;
    height: 40px;
    margin-bottom: -70px;
  }
`;

export const ContainerDescription = styled.div.attrs({
  className: 'container',
})`
  position: relative;
  z-index: 10;
  min-height: auto;

  > h1 {
    font-size: 40px;
    margin: 0;
    font-weight: 600;
    color: #00bfa5;
  }

  > p {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    padding: 23px 0;
  }

  @media screen and (max-width: 768px) {
    > h1 {
      font-size: 20px;
    }

    > p {
      font-size: 18px;
      font-weight: 300;
      padding: 18px 0;
    }
  }
`;

export const ContainerMedia = styled.div.attrs({
  className: 'container',
})`
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    & img {
      width: 100%;
      height: 100%;
      max-width: 250px;
      margin-right: 40px;
      border-radius: 10px;
    }
  }

  > h6 {
    width: 100%;
    font-size: 1.28rem;
    color: #f5f5f5;
    opacity: 0.5;
  }

  @media screen and (max-width: 768px) {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      & img {
        margin-right: 15px;
        max-width: 100px;
      }
    }

    > h6 {
      font-size: 1.05rem;
      font-weight: 300;
    }
  }
`;

export const ContainerSocialMedia = styled.div`
  width: 100%;
  height: 90px;
  margin: 70px 0 45px;
  background: transparent
    linear-gradient(
      to right,
      transparent 5%,
      #2b2a2a 20%,
      #2b2a2a 80%,
      transparent 95%
    );

  @media screen and (max-width: 768px) {
    height: 70px;
  }
`;

export const SocialMediaContent = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;

  @media screen and (max-width: 768px) {
    left: -10%;
  }
`;

export const SocialMediaUrl = styled.div`
  min-width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  padding-right: 10px;

  > svg {
    width: 30px;
    height: 30px;
    margin: 0 15px 0 30px;
    color: #ffffff;
  }

  > a {
    font-size: 20px;
    padding: 4px 0 0;
    color: #e4e4e4;
    text-decoration: none;
    transition: color 0.3s ease;
    overflow: hidden;
    &:hover {
      color: #a1a1a1;
    }
  }

  @media screen and (max-width: 768px) {
    > svg {
      width: 30px;
      height: 30px;
      margin: 0 10px 0 -5px;
      color: #ffffff;
    }

    > a {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      word-wrap: unset;
      letter-spacing: -0.5px;
      padding: 4px 0 0;
      color: #e4e4e4;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: color 0.3s ease;

      &:hover {
        color: #a1a1a1;
      }
    }
  }
`;

export const ShadowText = styled.div`
  position: absolute;
  right: 0;
  width: 300px;
  height: 50px;
  background-image: linear-gradient(to right, transparent 10%, #2b2a2a 70%);

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SocialMediaButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 2;

  & svg {
    margin-right: 25px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SocialMediaButtonMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 75%;
    height: 70px;
    padding: 18px;
    background: #252425;
    border-radius: 0 0 0 60px;

    & svg {
      margin-right: 15px;
    }
  }
`;

export const SocialMediaOther = styled.div`
  width: 100%;
  min-height: 330px;

  @media screen and (max-width: 768px) {
    margin: 100px 0 30px;
    min-height: 360px;
  }
`;

export const SocialMediaOtherContent = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 85%;
  margin: 0 auto;

  > h1 {
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    color: #f5f5f5;
    opacity: 0.5;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    > h1 {
      width: 100%;
      font-size: 1.1rem;
      font-weight: 500;
      color: #575757;
    }
  }
`;

export const WrapperCarousel = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 200px;
  font-size: 14px;
`;

export const CarouselImages = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 250px;
  cursor: pointer;

  & .swiper-container {
    width: 100%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  & .swiper-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  & .swiper-slide {
    text-align: center;
    width: auto;

    @media screen and (max-width: 768px) {
      &::after {
        content: '';
        position: absolute;
        height: 3px;
        left: 0;
        bottom: -20px;
        background: #707070;
        width: 1000%;
      }
    }
  }
`;

export const ContainerSwiper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 250px;
`;

export const CertificateBlock = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;
  width: 560px;
  height: 230px;
  background: #2b2a2a;
  padding: 30px 40px;
  border-radius: 21px;
  text-align: left;

  @media screen and (max-width: 768px) {
    width: 340px;
    padding: 26px 22px;
  }
`;

export const ContainerBoxMediaDescription = styled.div`
  position: relative;
  z-index: 10;
  min-height: auto;

  > h1 {
    min-height: 30px;
    font-size: 20px;
    margin: 0;
    padding-bottom: 10px;
    font-weight: 600;
    color: #00bfa5;
  }

  > p {
    min-height: 80px;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    margin: 0;
    padding-bottom: 18px;
    color: #ffffff;
    opacity: 0.52;
  }

  & img {
    width: 100%;
    height: 100%;
    max-width: 130px;
    max-height: 30px;
    object-fit: contain;
    object-position: left;
  }

  @media screen and (max-width: 768px) {
    > h1 {
      font-size: 17px;
    }

    > p {
      font-size: 18px;
      position: relative;
      max-height: calc(20px * 5);
      overflow: hidden;
      padding-right: 1rem;
      margin-bottom: 15px;

      &::before {
        position: absolute;
        content: '.....';
        bottom: 0;
        right: 30px;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        background: white;
      }
    }

    & img {
      max-width: 100px;
      height: 30px;
    }
  }
`;

export const ContainerButton = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 82%;
  height: 270px;
`;

export const SliderButtonPrev = styled.button`
  position: absolute;
  z-index: 10;
  top: 100px;
  left: -30px;
  width: 55px;
  height: 55px;
  background: transparent url(${ArrowSlider}) no-repeat;
  border: none;
  overflow: hidden;
  transform: scaleX(-1);

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SliderButtonNext = styled.button`
  position: absolute;
  z-index: 10;
  top: 100px;
  right: -30px;
  width: 55px;
  height: 55px;
  background: transparent url(${ArrowSlider}) no-repeat;
  border: none;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
