// TODO: REFACTORING
import React from 'react';

const Logo = () => {
  return (
    <svg
      width="130"
      height="48"
      viewBox="0 0 968 365"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.467 319.368C252.71 240.648 276.532 174.858 276.532 174.858L283.186 157.908C288.28 145.385 325.008 65.0537 325.008 65.0537C370.24 50.5303 350.294 14.8556 350.294 14.8556C333.749 -11.3663 308.209 6.52544 308.209 6.52544L301.335 23.0046L286.307 58.9963C282.701 66.4611 279.678 73.8377 277.805 78.6304L97.3999 3.80003L0.105469 2.81309L70.7814 79.2331L272.436 92.5406C270.116 98.6407 266.894 107.242 263.562 116.183L120.354 120.522L71.965 159.076L130.331 202.085L258.361 130.173C252.954 144.741 248.396 157.093 248.396 157.093C247.353 160.316 245.924 164.889 245.924 164.889C239.664 179.829 190.942 328.241 188.995 334.715C187.023 341.171 190.162 346.404 190.162 346.404C198.368 356.917 214.009 362.548 214.009 362.548C222.207 367.003 223.391 360.131 223.391 360.131C224.943 340.229 245.792 313.853 245.792 313.853C234.99 314.804 228.467 319.368 228.467 319.368Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.968 277.798C299.968 277.798 306.982 251.124 327.279 204.231C327.279 204.231 402.584 127.62 411.071 75.0228C411.071 75.0228 407.739 66.6383 383.751 60.9249C383.751 60.9249 321.378 103.997 272.288 277.798C272.288 277.798 279.293 293.816 297.381 294.187C297.381 294.187 331.329 254.166 363.436 234.346L370.082 216.816C370.082 216.816 342.412 225.201 299.968 277.798ZM393.711 81.8861C393.711 81.8861 401.093 76.9242 396.675 87.2192C392.247 97.5142 375.632 132.193 340.939 173.753C340.939 173.753 373.782 101.317 393.711 81.8861Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M445.379 277.038C463.107 217.577 504.429 141.727 504.429 141.727C497.064 132.582 471.585 129.141 471.585 129.141C471.585 132.582 463.852 145.159 463.852 145.159C446.124 133.343 429.518 128.001 429.518 128.001C361.236 174.894 354.23 279.328 354.23 279.328C365.663 294.187 378.955 292.285 378.955 292.285C403.68 281.23 431.736 230.534 431.736 230.534C424.354 250.363 417.717 278.939 417.717 278.939C426.204 293.426 441.328 294.187 441.328 294.187C458.679 275.127 488.945 247.692 488.945 247.692L497.801 223.299C491.172 226.351 445.379 277.038 445.379 277.038ZM392.975 263.311C392.975 263.311 381.165 276.277 383.392 256.837C383.392 256.837 391.879 202.329 436.164 147.45L454.988 165.35C454.988 165.35 415.858 239.679 392.975 263.311Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M583.056 238.547C635.478 133.343 591.175 128.761 585.643 131.052C580.093 133.343 557.955 155.445 557.955 155.445C559.805 149.722 566.082 137.146 566.082 137.146C556.85 126.099 531.757 124.569 531.757 124.569C484.526 237.397 458.679 343.362 458.679 343.362C466.421 355.939 481.185 358.61 481.185 358.61C484.131 347.925 508.873 271.705 508.873 271.705C526.216 299.529 535.08 295.708 535.08 295.708C556.122 288.854 573.842 253.025 573.842 253.025C590.439 249.603 603.362 248.072 603.362 248.072L610.008 231.294C601.881 235.876 583.056 238.547 583.056 238.547ZM528.075 277.418C525.102 273.597 519.21 263.7 519.21 263.7C526.584 262.179 540.244 260.649 540.244 260.649C538.026 266.362 528.075 277.418 528.075 277.418ZM579.006 164.219C577.515 175.265 569.405 211.863 547.986 248.072C547.986 248.072 523.638 254.166 515.888 254.936C515.888 254.936 539.867 193.945 543.567 186.701C543.567 186.701 556.482 170.693 576.411 153.534C576.411 153.534 580.47 153.163 579.006 164.219Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M697.72 238.547C750.133 133.343 705.839 128.761 700.316 131.052C694.766 133.343 672.627 155.445 672.627 155.445C674.469 149.722 680.746 137.146 680.746 137.146C671.505 126.099 646.421 124.569 646.421 124.569C599.181 237.397 573.343 343.362 573.343 343.362C581.093 355.939 595.858 358.61 595.858 358.61C598.804 347.925 623.537 271.705 623.537 271.705C640.88 299.529 649.744 295.708 649.744 295.708C670.778 288.854 688.497 253.025 688.497 253.025C705.094 249.603 718.026 248.072 718.026 248.072L724.672 231.294C716.545 235.876 697.72 238.547 697.72 238.547ZM642.73 277.418C639.775 273.597 633.866 263.7 633.866 263.7C641.248 262.179 654.908 260.649 654.908 260.649C652.699 266.362 642.73 277.418 642.73 277.418ZM693.652 164.219C692.197 175.265 684.06 211.863 662.65 248.072C662.65 248.072 638.302 254.166 630.543 254.936C630.543 254.936 654.531 193.945 658.231 186.701C658.231 186.701 671.146 170.693 691.083 153.534C691.083 153.534 695.134 153.163 693.652 164.219Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M771.289 278.052C756.525 285.676 751.597 281.103 751.597 281.103C739.787 276.024 756.279 233.087 756.279 233.087C804.519 217.07 818.038 229.52 818.038 229.52C820.502 219.868 808.455 211.492 808.455 211.492C840.913 180.734 839.221 150.248 839.221 150.248C823.694 131.695 803.282 127.376 803.282 127.376C780.881 131.948 756.051 164.726 734.869 199.531C713.721 234.346 715.922 258.748 715.922 258.748C721.095 293.309 752.597 295.844 752.597 295.844C781.135 296.604 822.712 260.278 822.712 260.278L833.794 231.294C822.475 245.782 786.063 270.437 771.289 278.052ZM817.302 146.182C817.302 146.182 813.129 202.592 765.143 215.032C765.143 215.032 778.917 180.471 817.302 146.182Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M816.793 280.089C816.793 280.089 837.493 201.822 870.451 129.667C870.451 129.667 897.026 131.695 903.417 142.361C903.417 142.361 884.462 179.729 879.069 192.179C879.069 192.179 905.627 157.871 934.674 135.253C934.674 135.253 938.856 125.846 941.556 122.287C941.556 122.287 962.476 121.038 967.894 134.493C967.894 134.493 933.937 178.19 927.046 216.563C927.046 216.563 901.208 214.779 922.127 165.984C922.127 165.984 871.924 202.329 840.185 293.553C840.185 293.553 824.184 295.083 816.793 280.089Z"
        fill="white"
      ></path>
    </svg>
  );
};

export default Logo;
