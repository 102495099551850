import styled from 'styled-components';

export const Wrapper = styled.div.attrs({
  className: 'container',
})`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, transparent 25%, #030202 70%),
    url(${({ img }) => img});
  background-size: 101%;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 765px;
  margin-bottom: 100px;
  border-radius: 30px 30px 30px 0;

  @media screen and (max-width: 768px) {
    top: -390px;
    background-image: linear-gradient(to bottom, transparent 25%, #030202 50%),
      url(${({ img }) => img});
    min-height: 300px;
    border-radius: 10px;
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 96%;

  @media screen and (max-width: 768px) {
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 645px;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const ContainerText = styled.div`
  width: 48%;
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const AwardMainTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  color: #00bfa5;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
  line-height: 32px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 23px;
  }
`;

export const AwardMainContent = styled.p`
  color: #ffffff;
  font-size: 27px;
  font-weight: 500;
  line-height: 31px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const AwardMainButton = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 50px;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid #00bfa5;
  border-radius: 8px;
  text-align: left;
  font-size: 18px;
  text-decoration: none;
  color: #ffffff;
  padding: 0 14px;

  & svg {
    width: 19px;
    height: 19px;
    transition: margin-right 0.3s ease;
  }

  &:hover {
    > svg {
      margin-right: 4px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 140px;
    height: 40px;
    font-size: 16px;

    & svg {
      width: 16px;
      height: 16px;
      transition: margin-right 0.3s ease;
    }
  }
`;

export const ContainerImage = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 300px;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const AwardMainImage = styled.div`
  width: 95%;
  padding-top: 66.8%;
  background-image: url(${({ img }) => img});
  background-size: cover;
  border-radius: 24px;

  @media screen and (max-width: 768px) {
    width: 80%;
    padding-top: 45%;
    border-radius: 14px;
  }
`;

export const AwardMainImageText = styled.h1`
  font-size: 18px;
  font-weight: 500;
  color: #e4e4e4;
  opacity: 0.58;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    width: 80%;
    font-size: 0.85rem;
    text-align: left;
    padding-left: 15px;
    margin-top: 5px;
  }
`;
