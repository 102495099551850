import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import HeroAward from '@components/HeroAward';
import AwardMain from '@components/AwardMain';
import AwardTheme from '@components/AwardTheme';
import SectionArticles from '@components/SectionArticles';
import SEO from '@components/seo';

const Premiacao = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: {
        award_hero: header,
        award_main: main,
        award_security: security,
        award_innovation: innovation,
        award_business: business,
        award_articles: articles,
      },
    },
  } = data;
  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      pageLang={page.polylang_current_lang}
      translations={page.polylang_translations}
      useTranslationsPath={true}
      showNewsletter={true}
      showFooter={true}
    >
      <SEO {...page} />
      <HeroAward {...header} />
      <AwardMain {...main} />
      <AwardTheme {...security} hasHeader />
      <AwardTheme {...innovation} />
      <AwardTheme {...business} isLast />
      <SectionArticles {...articles} negativeOffset />
    </Layout>
  );
};

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        award_hero {
          title
          text_color
          text_shadow
          description
          trophy {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        award_main {
          title
          description
          link
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image_text
          image_picture {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        award_security {
          title
          subtitle
          description
          trophy {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          articles {
            title
            award_publications {
              link
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          link_award
          other_award {
            title
            other_publications {
              title
              description
              publications {
                link
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        award_innovation {
          title
          subtitle
          description
          trophy {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          articles {
            title
            award_publications {
              link
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          link_award
          other_award {
            title
            other_publications {
              title
              description
              publications {
                link
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        award_business {
          title
          subtitle
          description
          trophy {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          articles {
            title
            award_publications {
              link
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          link_award
          other_award {
            title
            other_publications {
              title
              description
              publications {
                link
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        award_articles {
          posts {
            excerpt
            link
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Premiacao;
