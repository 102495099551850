// TODO: REFACTORING
import React, { useRef } from 'react';
import Style from './style.module.css';
import BlogPostCard from '../BlogPostCard';
import Swiper from 'react-id-swiper';

const SectionArticles = ({ posts, negativeOffset }) => {
  const swiperRef = useRef(null);
  let sectionClasses = Style.sectionArticles;
  if (negativeOffset) sectionClasses += ' ' + Style.negativeOffset;

  const params = {
    direction: 'horizontal',
    freeMode: true,
    loop: false,
    speed: 600,
    centerMode: true,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
    },
  };

  const gotoNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const gotoPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const postsList = posts.map((post, index) => {
    return (
      <div key={index}>
        <BlogPostCard {...post} image={post.image} />
      </div>
    );
  });

  return (
    <section className={sectionClasses}>
      <div className={Style.effectContainer}>
        <div className={Style.hollowPortion}></div>
        <div className={Style.solidPortion}></div>
      </div>
      <Swiper {...params}>{postsList}</Swiper>
    </section>
  );
};

export default SectionArticles;
