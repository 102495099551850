import React from 'react';

const Icon = () => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="arrow-right"
      className="svg-inline--fa fa-arrow-right fa-w-14"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="#FFFFFF"
        d="M190.5 66.9l22.2-22.2a23.9 23.9 0 0133.9 0L441 239a23.9 23.9 0 010 33.9L246.6 467.3a23.9 23.9 0 01-33.9 0l-22.2-22.2a24 24 0 01.4-34.3L311.4 296H24a24 24 0 01-24-24v-32a24 24 0 0124-24h287.4L190.9 101.2a23.9 23.9 0 01-.4-34.3z"
      />
    </svg>
  );
};

export default Icon;
