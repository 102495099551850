// TODO: REFACTORING
import React from 'react';

const TwitterIcon = ({ color = '#FFFFFF' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42.9" height="42.9">
      <path
        d="M21.5 0a21.5 21.5 0 1021.4 21.5A21.5 21.5 0 0021.5 0zm9.8 16.7v.7a14 14 0 01-14 14 14 14 0 01-7.6-2.2 10 10 0 001.2 0 9.9 9.9 0 006.1-2 5 5 0 01-4.6-3.5 5 5 0 002.2 0 5 5 0 01-4-4.9 4.9 4.9 0 002.3.6 5 5 0 01-1.5-6.6 14 14 0 0010.1 5.1 5 5 0 018.4-4.5 9.9 9.9 0 003.2-1.2 5 5 0 01-2.2 2.8 9.8 9.8 0 002.8-.8 10 10 0 01-2.4 2.5zm0 0"
        fill={color}
      />
    </svg>
  );
};

export default TwitterIcon;
