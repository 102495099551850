// TODO: REFACTORING
import React from 'react';

const ForwardIcon = ({ color = '#FFFFFF' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="27.5">
      <path
        fill={color}
        d="M33 12.8L20.2 0v7.3C7.3 9.2 1.8 18.3 0 27.5c4.6-6.4 11-9.3 20.2-9.3v7.5z"
        opacity=".5"
      />
    </svg>
  );
};

export default ForwardIcon;
