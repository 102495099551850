// TODO: REFACTORING
import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import * as Styled from './AwardMain.styles';
import solveImageLink from '@utils/solveImageLink';

const AwardMain = ({
  title,
  description,
  link,
  image_picture,
  image,
  image_text,
}) => {
  image = solveImageLink(image);
  image_picture = solveImageLink(image_picture);

  return (
    <Styled.Wrapper img={image && image.src}>
      <Styled.Container>
        <Styled.ContainerText>
          <Styled.AwardMainTitle>{title}</Styled.AwardMainTitle>
          <Styled.AwardMainContent>{description}</Styled.AwardMainContent>
          <Styled.AwardMainButton
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Saiba mais
            <Icon />
          </Styled.AwardMainButton>
        </Styled.ContainerText>
        <Styled.ContainerImage>
          <Styled.AwardMainImage img={image_picture && image_picture.src} />
          <Styled.AwardMainImageText>{image_text}</Styled.AwardMainImageText>
        </Styled.ContainerImage>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

AwardMain.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  description: PropTypes.string,
  link: PropTypes.string,
  image_picture: PropTypes.object.isRequired,
  image_text: PropTypes.string,
};

export default AwardMain;
