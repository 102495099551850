// TODO: REFACTORING
import React from 'react';

const FacebookIcon = ({ color = '#FFFFFF' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42.9" height="42.9">
      <path
        d="M43 21.5a21.5 21.5 0 10-21.5 21.4h.3V26.2h-4.6v-5.4h4.6v-4c0-4.5 2.8-7 7-7a37.5 37.5 0 014 .2v4.8h-2.7c-2.3 0-2.7 1-2.7 2.6v3.4h5.3l-.7 5.4h-4.6v15.9a21.5 21.5 0 0015.5-20.6z"
        fill={color}
      />
    </svg>
  );
};

export default FacebookIcon;
